<template>
  <PageListLayout
    title="Thêm hợp đồng"
    useBack
    noFilter
    linkBack="/kinhdoanh/thembaogia/"
    :iconFilter="iconFilter"
    @isShowFilter="
      (data) => {
        isHistory = data;
      }
    "
  >
    <template v-slot:side-right>
      <div class="d-flex mr-5">
        <div class="ml-4">
          <v-btn depressed color="success" @click="submit">
            <v-icon left>mdi-account-plus </v-icon>
            Tạo mới hợp đồng
          </v-btn>
        </div>
      </div>
    </template>
    <div>
      <v-form ref="form">
        <v-card-title class="pt-0 pl-6">Hợp đồng</v-card-title>
        <v-layout column class="pl-6 pr-6">
          <v-row>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Mã báo giá</div>
              <SelectAsync
                prepend-inner-icon="mdi-folder-account"
                outlined
                dense
                item-text="price_code"
                item-value="id"
                :rules="requestRules"
                :disabled="true"
                @change="onChange($event)"
                v-model="form.product_price_id"
                :api="getProductPrices"
              >
              </SelectAsync>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Mã hợp đồng</div>
              <v-text-field
                v-model="form.contract_code"
                placeholder="Mã hợp đồng"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker"
                :rules="contract_codeRules"
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Ngày ký hợp đồng</div>
              <DateTimePicker
                placeholder="Ngày ký hợp đồng"
                v-model="form.contract_signing_date"
                :input-readonly="false"
                format="DD/MM/YYYY"
                :rules="sign_dateRules"
                hideDetails="auto"
              ></DateTimePicker>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Ngày hiệu lực hợp đồng</div>
              <DateTimePicker
                v-model="form.contract_date"
                :input-readonly="false"
                format="DD/MM/YYYY"
                :rules="contract_dateRules"
                hideDetails="auto"
                placeholder="Ngày hiệu lực hợp đồng"
              ></DateTimePicker>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Ngày hết hạn hợp đồng</div>
              <DateTimePicker
                v-model="form.contract_due_date"
                :input-readonly="false"
                format="DD/MM/YYYY"
                :rules="contract_due_dateRules"
                hideDetails="auto"
                placeholder="Ngày hết hạn hợp đồng"
              ></DateTimePicker>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Loại hợp đồng</div>
              <v-autocomplete
                prepend-inner-icon="mdi-format-list-bulleted"
                placeholder="Loại hợp đồng"
                v-model="form.contract_type"
                :items="contractsTypeList"
                outlined
                dense
                item-text="name"
                item-value="id"
                :rules="contract_typesRules"
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Khách hàng</div>
              <v-select
                prepend-inner-icon="mdi-format-list-bulleted"
                v-model="form.customer_id"
                :items="customersList"
                outlined
                dense
                item-text="name"
                item-value="id"
                readonly
                placeholder="Khách hàng"
              >
              </v-select>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Người đại diện khách hàng</div>
              <v-text-field
                v-model="form.customer_agent_name"
                placeholder="Người đại diện khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-account-network"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Chức vụ người đại diện</div>
              <v-text-field
                v-model="form.customer_agent_position"
                placeholder="Chức vụ người đại diện"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Mã số thuế khách hàng</div>
              <v-text-field
                v-model="form.customer_tax"
                placeholder="Mã số thuế khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Địa chỉ khách hàng</div>
              <v-text-field
                v-model="form.customer_address"
                placeholder="Địa chỉ khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-map-marker"
                disabled
              ></v-text-field>
            </v-col>

            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Số điện thoại khách hàng</div>
              <v-text-field
                v-model="form.customer_phone"
                placeholder="Số điện thoại khách hàng"
                outlined
                dense
                prepend-inner-icon="mdi-cellphone"
                disabled
              ></v-text-field>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Thuế VAT</div>
              <v-select
                prepend-inner-icon="mdi-format-list-bulleted"
                v-model="form.type_vat"
                :items="VAT_LIST"
                outlined
                dense
                item-text="name"
                item-value="value"
                placeholder="Thuế VAT"
              >
              </v-select>
            </v-col>
            <v-col cols="3" class="zero-vertical">
              <div class="label-form">Chọn thời gian hết hạn</div>
              <v-autocomplete
                prepend-inner-icon="mdi-format-list-bulleted"
                placeholder="Thời gian hết hạn"
                v-model="form.expired_date"
                :items="expired_date_list"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="3" class="zero-vertical pt-0 mt-0">
              <v-checkbox
                class="mt-0"
                v-model="form.is_extend"
                label="Gia hạn hợp đồng(1 năm)"
                color="var(--primary-color)"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" class="zero-vertical">
              <div class="label-form">Tên tin đính kèm</div>
              <UploadFile
                :table="TABLE"
                :reference_id="form.id"
                :editing="editing"
                v-model="form.files"
                multiple
              />
              <div
                style="
                    width: 100%;
                    text-align: center;
                    font-size: 13px;
                    color: teal;
                  "
              >
                Tệp đính kèm không quá 20 Mb
              </div>
            </v-col>
            <v-col cols="6" class="zero-vertical mt-1">
              <div class="label-form">Ghi chú</div>
              <v-textarea
                v-model="form.description"
                placeholder="Ghi chú"
                outlined
                dense
                rows="2"
                prepend-inner-icon="mdi-note"
                no-resize
              ></v-textarea>
            </v-col>
          </v-row>
        </v-layout>
        <ProductComponent
          :productsList="productsList"
          v-model="form.ContractDetails"
          title="Sản phẩm"
          :table="TABLE"
          hide-select-item
          hide-add-product
        />

        <SigiDigital ref="sign-component" />
      </v-form>
    </div>
  </PageListLayout>
</template>
<script>
import {
  addContracts,
  editContracts,
  getContractsById,
  printContract,
  resignContract,
} from "@/api/hopdong";

import KHACHHANG from "@/api/khachhang";
import { getAllContractTypes } from "@/api/loaihopdong";
import { getSourceProductPrice, getProductPricesById } from "@/api/baogia";
import UploadFile from "@/components/upload/UploadFile";
import JsonToFromData from "@/utils/JsonToFormData.js";
import { TABLE, VAT_LIST, EXPIRE_DATE } from "@/constants/hopdong";
import { numberFormat } from "@/utils/moneyFormat";
import { saveAs } from "file-saver";
import ProductComponent from "../SanPhamComponent/Product.vue";
import SelectAsync from "@/components/Async/SelectAsync";
import SigiDigital from "../../../components/SignDigital/SigiDigital.vue";
export default {
  components: {
    UploadFile,
    ProductComponent,
    SelectAsync,
    SigiDigital,
  },
  data: () => ({
    VAT_LIST,
    loadmorable: false,
    key: 1,
    loadingResign: false,
    page: 1,
    TABLE,
    dataHistory: [],

    isHistory: false,
    dialogDelete: false,
    isContractAddendum: false,
    showPickContractDate: false,
    showPickContractDueDate: false,
    loadingProductDetails: false,
    date: null,
    editing: false,
    customersList: [],
    productsList: [],
    ProductData: [],
    productPriceList: [],
    contractsTypeList: [],
    tab: null,
    btnLoading: false,
    requestRules: [(v) => !!v || "Mã báo giá không thể bỏ trống"],
    contract_codeRules: [(v) => !!v || "Mã hợp đồng không thể bỏ trống"],
    contract_dateRules: [
      (v) => !!v || "Ngày hiệu lực hợp đồng không thể bỏ trống",
    ],
    contract_due_dateRules: [
      (v) => !!v || "Ngày kết thúc hợp đồng không thể bỏ trống",
    ],
    sign_dateRules: [(v) => !!v || "Ngày ký hợp đồng không thể bỏ trống"],
    contract_typesRules: [(v) => !!v || "Loại hợp đồng không thể bỏ trống"],
    form: {
      product_price_id: null,
      request_id: null,
      contract_code: "",
      contract_date: "",
      contract_due_date: "",
      contract_type: null,
      customer_id: null,
      customer_name: "",
      customer_agent_name: "",
      customer_agent_position: "",
      customer_tax: "",
      customer_address: "",
      customer_phone: "",
      expired_date: null,
      status: null,
      description: "",
      ContractDetails: [],
      files: [],
      type_vat: 2,
    },

    btnLoadingPrint: false,
    itemsLang: [
      {
        title: "Tiếng Việt",
        img: require("../../../assets/images/lang/vietnam-flag-icon-32.png"),
        key: "vn",
        show: ["PL", "XL", "TH", "TG"],
      },
      {
        title: "Tiếng Anh",
        img: require("../../../assets/images/lang/united-states-of-america-flag-icon-32.png"),
        key: "en",
        show: ["PL", "XL", "KT"],
      },
      {
        title: "Tiếng Anh(NSX)",
        img: require("../../../assets/images/lang/united-states-of-america-flag-icon-32.png"),
        key: "en-nsx",
        show: ["PL"],
      },
      {
        title: "Tiếng Trung",
        img: require("../../../assets/images/lang/china-flag-icon-32.png"),
        key: "cn",
        show: ["KT", "PL", "XL"],
      },
      {
        title: "Tiếng Hàn",
        img: require("../../../assets/images/lang/south-korea-flag-icon-32.png"),
        key: "ko",
        show: ["KT"],
      },
    ],
    expired_date_list: EXPIRE_DATE,
    total: null,

    perPage: 10,
  }),

  created() {
    this.getInfoContract();
  },

  mounted() {
    this.getCustomersList();
    this.getContractTypes();
  },
  computed: {
    USER() {
      return this.$store.state.User.me;
    },
    formTitle() {
      return this.editedIndex === -1 ? "Thêm sản phẩm" : "Sửa sản phẩm";
    },
    iconFilter() {
      return {
        icon: "mdi-timetable",
        tooltip: `${this.isHistory ? "Ẩn" : "Hiện"} lịch sử`,
      };
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    isHistory(val) {
      if (val) {
        this.isContractAddendum = false;
      }
    },
    isContractAddendum(val) {
      if (val) {
        this.isHistory = false;
      }
    },
  },
  methods: {
    getImageUrl(url) {
      if (url.charAt(0) === "/") {
        url = url.substring(1);
      }
      let path = `${process.env.VUE_APP_BASE}${url}`;
      return path;
    },
    async submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate()) {
        this.btnLoading = true;
        try {
          let data = { ...this.form };
          if (data.files) {
            data.files = data.files.map((x) => x.file);
          }
          console.log(data);
          data = JsonToFromData(data, null);

          await addContracts(data);
          this.alert(true);
          this.$router.push("/kinhdoanh/quanlyhopdong");
        } finally {
          this.btnLoading = false;
          this.show = false;
        }
      }
    },
    alert(isCreate) {
      this.$emit("on-done");
      this.$store.dispatch("toastAlert/success", "Thêm mới thành công");
    },
    async getCustomersList() {
      let res = await KHACHHANG.getAllCustomers();
      this.customersList = res.data;
    },
    async getContractTypes() {
      this.contractsTypeList = await getAllContractTypes();
    },
    async getInfoContract() {
      const product_price_id = Number(this.$route.params.baogia_id);
      const item = await getProductPricesById({ id: product_price_id });
      this.form.product_price_id = product_price_id;
      this.form.customer_id = item.customer_id;
      this.form.customer_name = item.customers?.name;
      this.form.customer_agent_name = item.customers?.agent_name;
      this.form.customer_agent_position = item.customers?.agent_position;
      this.form.customer_tax = item.customers?.tax;
      this.form.customer_address = item.customers?.address;
      this.form.customer_phone = item.customers?.phone;
      const detailList = item.product_price_detail || [];
      this.ProductData = detailList?.map((item) => ({
        product_code: item.product_code,
        type: item.type,
        product_name: item.product_name,
        product_unit: item.unit ? item.unit : item.product_unit,
        product_price: item.product_price,
        product_image: item.url_image,
        description: "",
        vat: item.vat,
        id: item.id,
      }));
      this.form.ContractDetails = this.ProductData;
    },
    async getProductPrices(params) {
      return getSourceProductPrice(params);
    },
    formatnumber(number) {
      return numberFormat(number);
    },
  },
};
</script>
<style scoped>
.zero-vertical {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
::v-deep .v-list-item.header__right__list_item {
  height: 40px;
}
::v-deep .header__right__list_item .v-list-item__content {
  padding-bottom: 0;
}
</style>
<style lang="scss" scoped>
.historyItem {
  position: relative;
  &:not(:last-child) {
    &::after {
      content: "";
      height: 1px;
      background-color: #cecece;
      width: 100%;
      z-index: 1;
      display: block;
      margin: 10px 0 5px;
    }
  }
}
</style>
